import React, {useCallback, useState} from "react";
import {Divider, Dropdown} from "react-materialize";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {axiosClient} from "../api/httpClient";
import useInterval from "../hooks/UseInterval";
import {BiLinkExternal} from 'react-icons/bi';
import {connect} from "react-redux";
import * as actions from '../actions';

// eslint-disable-next-line no-unused-vars
function countUnread(notifications, myId) {
    return notifications.reduce((unread, notification) => unread + isUnread(notification, myId), 0);
}

function isUnread(notification, myId) {
    return !notification.seen && notification.recipient_client_id === myId;
}

export default connect(mapStateToProps, actions)((props) => {
   let me = props.me;
   const notificationsLists = (props.auth.notificationList !== 'undefined') ? props.auth.notificationList : [];
   const counts = (props.auth.notificationCount !== 'undefined') ? props.auth.notificationCount : 0;

   const [notificationsList, setNotificationsList] = useState(notificationsLists);
   const [count, setCount] = useState(counts);
    const myId = me?.client_id;

    const fetchNotifications = useCallback(() => {
        axiosClient.get("/api/notifications")
            .then(({data}) => {
                setNotificationsList(data.notifications);
                fetchCount();
            }).catch(err => {
                console.error(err);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchCount = useCallback(() => {
        props.unSeenMessages();
        axiosClient.get("/api/notifications/getCount")
            .then(({data}) => {
                setCount(data.notificationsCount);
            }).catch(err => {
                console.error(err);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useInterval(fetchCount, 30000);

    // useEffect(() => {
    //     if(me) {
    //        fetchNotifications();
    //        fetchCount();
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [me]);

    function markAsRead() {
        let resultIds = notificationsList?.map(notificationsList => notificationsList.id);
        //if (countUnread(notificationsList, myId)) {
            axiosClient.post("/api/notifications/read",{resultIds})
                .then(() => {})
                .catch(err => {
                    console.error(err);
                });
        //}
    }

    // this regex was not working for all notification 
    //const cardIdRegex = /^.*card-(\d+)/gm;
    //const cardIdRegex = (/card-(\d+)/gm);

    return (
        <Dropdown
            id="notificationDropdown"
            options={{
                coverTrigger: false,
                onOpenEnd: markAsRead,
                constrainWidth: false,
                closeOnClick:false,
                onCloseStart:()=>fetchNotifications()
            }}
            trigger={<a href="/#" className="notification-nav iconColor"><FontAwesomeIcon icon="bell"/>&nbsp;&nbsp;({ count })</a>}
        >
            <Link to={"/notifications"}> 
                <BiLinkExternal style={{ float: 'left',  marginRight: '5px' }} size='25px' /> Open all notifications 
            </Link>
             {
                React.Children.toArray(notificationsList?.flatMap(notification => {
                    
                    let cardId = ((/^.*card-(\d+)/gm).exec(notification.action_link) || [])[1];
                    // working: let cardId = (/card-(\d+)/gm).exec(notification?.action_link)[1];
                    return [
                        <Link   to={
                            cardId 
                              ? `/card-details/${cardId}` 
                              : notification?.action_link?.includes("/ythelper/dashboard") ? "/ythelper/dashboard" : "/"  }    
                              target={cardId ? "_blank" : undefined}                      
                                className={isUnread(notification, myId) ? "unread": ""}>
                            {notification.data}
                            <br/>
                            <strong>{notification.created_at}</strong>
                        </Link>,
                        <Divider/>
                    ];
                }))
            }
        </Dropdown>
    );
})

function mapStateToProps({users,auth}) {
    return {users,auth};
}
